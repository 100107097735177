<template>
  <div v-if="supplier">
    <SaveBar></SaveBar>
    <v-card
      flat
      class="px-5 ml-5 mt-10 mb-10 pb-10"
    >
      <v-row>
        <v-col class="pa-0 col-12 col-md-8 col-xl-6">
          <v-card-title>
            {{ $t('stripeIntegration') }}
          </v-card-title>
          <template v-if="!supplier?.stripeId">
            <v-card-text>
              <p>
                <v-btn
                  color="primary"
                  class="text-none"
                  depressed
                  :ripple="false"
                  :loading="btnAddSupplierStripeAccountLoading"
                  @click="addSupplierStripeAccount"
                >
                  <v-icon class="mr-3">
                    fab fa-cc-stripe
                  </v-icon>
                  {{ $t('startStripeIntegration') }}
                </v-btn>
              </p>
            </v-card-text>
          </template>
          <template v-else-if="supplier?.stripeId && stripeAccount && !stripeAccount?.details_submitted">
            <v-card-text>
              <p>
                <v-btn
                  color="orange"
                  class="text-none white--text"
                  depressed
                  :ripple="false"
                  @click="getSupplierStripeAccountLink"
                >
                  {{ $t('configureStripe') }}
                  <v-icon class="ml-3">
                    fas fa-external-link-alt
                  </v-icon>
                </v-btn>
              </p>
            </v-card-text>
          </template>
          <template v-else-if="supplier?.stripeId && stripeAccount && stripeAccount?.details_submitted">
            <v-card-text>
              <p>
                <v-btn
                  color="primary"
                  class="text-none"
                  depressed
                  :ripple="false"
                  @click="getSupplierStripeLoginLink"
                >
                  {{ $t('loginStripeDashboard') }}
                  <v-icon class="ml-3">
                    fas fa-external-link-alt
                  </v-icon>
                </v-btn>
              </p>
            </v-card-text>
          </template>
          <v-alert
            color="primary"
            outlined
            text
            type="info"
          >
            <p class="text-h6">
              {{ $t('stripeInfoTitle') }}
            </p>
            <p>
              {{ $t('stripeInfoDescription1') }}
            </p>
            <v-img
              lazy-src="../../../assets/stripe.png"
              width="500px"
              contain
              src="../../../assets/stripe.png"
              alt="stripe"
            ></v-img>
            <p>
              {{ $t('stripeInfoDescription2') }}
            </p>
          </v-alert>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { EventBus, FormAddress, ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';
import SaveBar from '../../../components/saveBar/SaveBar';

const i18nData = require('./pageAdministrationPayment.i18n.json');

export default {
  name: 'PageAdministrationPayment',
  components: { SaveBar },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      supplier: null,
      currentUserIsAdmin: false,
      currentModified: null,
      rulesPaymentConfiguration: [
        (v) => { return v.length <= 1000 || this.$t('NCharactersMax', {n: 1000}); },
      ],
      /**
       * Stripe
       */
      btnAddSupplierStripeAccountLoading: false,
      stripeAccount: null,
    };
  },
  created() {
    /**
     * GET SUPPLIER
     */
    this.getSupplier();

    /**
     * EVENT TO THE SAVE BAR
     */
    EventBus.$on('saveBarSubmit', this.submitSaveBar);
    EventBus.$on('saveBarCancel', this.cancelSaveBar);
  },
  beforeDestroy() {
    EventBus.$off('saveBarSubmit', this.submitSaveBar);
    EventBus.$off('saveBarCancel', this.cancelSaveBar);
  },
  methods: {
    modifiedValue(key, ref) {
      if (this.currentModified !== key) {
        this.currentModified = key;
        EventBus.$emit('displaySaveBar', key);
        if (ref) {
          setTimeout(
            function() {
              ref.focus();
            },
            10
          );
        }
      }
    },
    submitSaveBar(key) {
      switch(key) {
      case 'paymentConfiguration':
        this.modifySupplierPaymentConfiguration();
        break;
      }
    },
    cancelSaveBar(key) {
      this.getSupplier();
    },

    /**
     * GET SUPPLIER
     */
    getSupplier() {
      this.$apiInstance.getSupplier(this.$route.params.supplierUUID).then(
        (supplierData) => {
          this.supplier = supplierData;
          this.getSupplierRoles();
          if(this.supplier.stripeId) {
            this.getSupplierStripeAccount();
          }
          this.currentModified = null;
          EventBus.$emit('hideSaveBar');
        },
        (error) => {
          /**
           * ERROR GET PRINTER
           */
          ApiErrorParser.parse(error);
        }
      );
    },

    /**
     * ADD SUPPLIER STRIPE ACCOUNT
     */
    addSupplierStripeAccount() {
      this.btnAddSupplierStripeAccountLoading = true;
      this.$apiInstance.addSupplierStripeAccount(this.$route.params.supplierUUID).then(
        (supplier) => {
          this.btnAddSupplierStripeAccountLoading = false;
          this.supplier = supplier;
          this.getSupplierStripeAccount();
          // this.getSupplierStripeAccountLink();
        },
        (error) => {
          /**
           * ERROR GET PRINTER
           */
          ApiErrorParser.parse(error);
        }
      );
    },

    /**
     * GET SUPPLIER STRIPE ACCOUNT
     */
    getSupplierStripeAccount() {
      this.$apiInstance.getSupplierStripeAccount(this.$route.params.supplierUUID).then(
        (stripeAccount) => {
          this.stripeAccount = stripeAccount;
        },
        (error) => {
          /**
           * ERROR GET PRINTER
           */
          ApiErrorParser.parse(error);
        }
      );
    },

    /**
     * GET SUPPLIER STRIPE ACCOUNT CONFIGURATION LINK
     */
    getSupplierStripeAccountLink() {
      this.$apiInstance.getSupplierStripeAccountLink(this.$route.params.supplierUUID).then(
        (stripeAccountLink) => {
          if(stripeAccountLink?.url) {
            window.open(stripeAccountLink.url, '_blank');
          }else{
            // eslint-disable-next-line
            console.error(stripeAccountLink);
          }
        },
        (error) => {
          /**
           * ERROR GET PRINTER
           */
          ApiErrorParser.parse(error);
        }
      );
    },

    /**
     * GET SUPPLIER STRIPE ACCOUNT LOGIN LINK TO THE DASHBOARD
     */
    getSupplierStripeLoginLink() {
      this.$apiInstance.getSupplierStripeLoginLink(this.$route.params.supplierUUID).then(
        (stripeLoginLink) => {
          if(stripeLoginLink?.url) {
            window.open(stripeLoginLink.url, '_blank');
          }else{
            // eslint-disable-next-line
            console.error(stripeLoginLink);
          }
        },
        (error) => {
          /**
           * ERROR GET PRINTER
           */
          ApiErrorParser.parse(error);
        }
      );
    },

    /**
     * GET SUPPLIER ROLES
     */
    getSupplierRoles() {
      this.$apiInstance.getSupplierRoles(this.$route.params.supplierUUID).then(
        (supplierRolesData) => {
          this.supplierRolesData = supplierRolesData;
          this.roleModified = false;
          this.supplierRolesData.admin.forEach((user) => {
            if (user._id === this.$appConfig.user._id) {
              this.currentUserIsAdmin = true;
            }
          });
        },

        (error) => {
          /**
           * ERROR GET SUPPLIER ROLES
           */
          this.supplierRolesErrorMessage = ApiErrorParser.parse(error);
        }
      );
    },

    /**
     * MODIFY SUPPLIER PAYMENT INFORMATION
     */

    modifySupplierPaymentConfiguration() {
      const modifySupplierPaymentConfigurationBody = new this.$BcmModel.ModifySupplierPaymentConfigurationBody(
        this.supplier.paymentConfiguration
      );
      this.$apiInstance.modifySupplierPaymentConfiguration(
        this.$route.params.supplierUUID,
        modifySupplierPaymentConfigurationBody
      ).then(data => {
        this.$notification.notify('SUCCESS', this.$t('PaymentConfigurationModified'));
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.getSupplier();
      });
    },
  },
};
</script>

<style scoped>
  .tooltip-opaque {
    opacity: 1 !important;
  }
</style>