var render = function () {
  var _vm$supplier, _vm$supplier2, _vm$stripeAccount, _vm$supplier3, _vm$stripeAccount2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.supplier ? _c('div', [_c('SaveBar'), _c('v-card', {
    staticClass: "px-5 ml-5 mt-10 mb-10 pb-10",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pa-0 col-12 col-md-8 col-xl-6"
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('stripeIntegration')) + " ")]), !((_vm$supplier = _vm.supplier) !== null && _vm$supplier !== void 0 && _vm$supplier.stripeId) ? [_c('v-card-text', [_c('p', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "primary",
      "depressed": "",
      "ripple": false,
      "loading": _vm.btnAddSupplierStripeAccountLoading
    },
    on: {
      "click": _vm.addSupplierStripeAccount
    }
  }, [_c('v-icon', {
    staticClass: "mr-3"
  }, [_vm._v(" fab fa-cc-stripe ")]), _vm._v(" " + _vm._s(_vm.$t('startStripeIntegration')) + " ")], 1)], 1)])] : (_vm$supplier2 = _vm.supplier) !== null && _vm$supplier2 !== void 0 && _vm$supplier2.stripeId && _vm.stripeAccount && !((_vm$stripeAccount = _vm.stripeAccount) !== null && _vm$stripeAccount !== void 0 && _vm$stripeAccount.details_submitted) ? [_c('v-card-text', [_c('p', [_c('v-btn', {
    staticClass: "text-none white--text",
    attrs: {
      "color": "orange",
      "depressed": "",
      "ripple": false
    },
    on: {
      "click": _vm.getSupplierStripeAccountLink
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('configureStripe')) + " "), _c('v-icon', {
    staticClass: "ml-3"
  }, [_vm._v(" fas fa-external-link-alt ")])], 1)], 1)])] : (_vm$supplier3 = _vm.supplier) !== null && _vm$supplier3 !== void 0 && _vm$supplier3.stripeId && _vm.stripeAccount && (_vm$stripeAccount2 = _vm.stripeAccount) !== null && _vm$stripeAccount2 !== void 0 && _vm$stripeAccount2.details_submitted ? [_c('v-card-text', [_c('p', [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "primary",
      "depressed": "",
      "ripple": false
    },
    on: {
      "click": _vm.getSupplierStripeLoginLink
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('loginStripeDashboard')) + " "), _c('v-icon', {
    staticClass: "ml-3"
  }, [_vm._v(" fas fa-external-link-alt ")])], 1)], 1)])] : _vm._e(), _c('v-alert', {
    attrs: {
      "color": "primary",
      "outlined": "",
      "text": "",
      "type": "info"
    }
  }, [_c('p', {
    staticClass: "text-h6"
  }, [_vm._v(" " + _vm._s(_vm.$t('stripeInfoTitle')) + " ")]), _c('p', [_vm._v(" " + _vm._s(_vm.$t('stripeInfoDescription1')) + " ")]), _c('v-img', {
    attrs: {
      "lazy-src": require("../../../assets/stripe.png"),
      "width": "500px",
      "contain": "",
      "src": require("../../../assets/stripe.png"),
      "alt": "stripe"
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.$t('stripeInfoDescription2')) + " ")])], 1)], 2)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }